
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

export default {
  props: {
    isLined: Boolean,
    isBordered: Boolean,
    isButton: Boolean,
    Icon: String,
    TitleColor: String,
    TextColor: String,
    isBlury: Boolean,
    data: Array,
    Carousel: Boolean,
  },
  data() {
    return {};
  },
  created() {
    this.Carousel &&
      setTimeout(() => {
        this.initCarousel();
      }, 100);
  },
  updated() {
    this.Carousel &&
      setTimeout(() => {
        this.initCarousel();
      }, 100);
  },
  methods: {
    initCarousel() {
      const swiper = new Swiper(".block-container", {
        allowTouchMove: true,
        grabCursor: false,
        paginationClickable: true,
        centeredSlides: false,
        observer: true,
        observeParents: true,
        // slidesPerView: "auto",
        breakpoints: {
          320: {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 45,
          },
          768: {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 40,
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        pagination: {
          el: ".swiper-pagination-block",
          clickable: true,
        },
      });
    },
  },
};

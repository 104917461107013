
export default {
  name: "Modal",
  props: {
    modal: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      showPoster: true,
      openModal: true,
    };
  },
  async mounted() {
    await this.getData();
    await this.openModal;
    if (this.openModal) {
      this.showModal();
    }
  },
  methods: {
    showModal() {
      this.$refs["home-modal"].show();
    },
    toggleModal() {
      this.$refs["home-modal"].toggle("#toggle-btn");
      localStorage.setItem("MG-modal-home", "false");
      this.openModal = false;
    },
    hidePoster() {
      this.showPoster = false;
    },
    async getData() {
      // Storage
      switch (localStorage.getItem("MG-modal-home")) {
        case null:
          localStorage.setItem("MG-modal-home", "true");
          this.openModal = true;
          break;
        case "false":
          this.openModal = false;
          localStorage.setItem("MG-modal-home", "false");
          break;
        case "true":
          this.openModal = true;
          break;
        default:
          break;
      }
    },
    close() {
      localStorage.setItem("MG-modal-home", "true");
      this.notificationStorage = false;
    },
  },
};


export default {
  props: {
    data: Object,
    showPlus: {
      type: Boolean,
      default: false,
    },
  },
};

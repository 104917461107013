
export default {
  props: {
    Link: String,
    Text: String,
    isExternal: Boolean,
    isRouting: Boolean,
    isRounded: Boolean,
    isBordered: Boolean,
    isUnderline: Boolean,
    enabled: Boolean,
    color: String,
    Size: String,
    Icon: String,
  },
};


export default {
  props: {
    data: Array,
    SectionTitle: String,
    BlockTitle: String,
    ColumnCount: String,
  },

  computed: {
    activeLanguage() {
      return this.activeLang.length > 0 ? this.activeLang : "tr";
    },
  },

  data() {
    return {
      activeLang: this.$api.list.getLangFromPath(this.$route.path),
    };
  },
};

/**
 * Vue.js component options for managing route-related data.
 *
 * @type {Object}
 *
 * @description
 * This Vue.js component provides data properties related to the current route.
 * It utilizes the '$api.list' API factory to handle language-specific route operations,
 * such as setting the language slug in the full path, extracting the path without the
 * language prefix, and determining the active language based on the current route.
 *
 * @example
 * / Using the RouteData component in a Vue file
 * export default {
 *   data() {
 *     return {
 *       / The 'fullPath' property contains the route path with the language slug.
 *       / The 'path' property contains the route path without the language prefix.
 *       / The 'activeLang' property contains the active language based on the route.
 *       / These properties are initialized using the '$api.list' API factory.
 *       fullPath: this.$api.list.setLangSlug(this.$route.path),
 *       path: this.$api.list.getPathWithOutLang(this.$route.path),
 *       activeLang: this.$api.list.getLangFromPath(this.$route.path),
 *     };
 *   },
 *   / Other component options
 * }
 */
export default {
    /**
     * Data properties related to the current route.
     *
     * @returns {Object} - An object containing route-related data properties.
     */
    data() {
        return {
            // The 'fullPath' property contains the route path with the language slug.
            // The 'path' property contains the route path without the language prefix.
            // The 'activeLang' property contains the active language based on the route.
            // These properties are initialized using the '$api.list' API factory.
            fullPath: this.$api.list.setLangSlug(this.$route.path),
            path: this.$api.list.getPathWithOutLang(this.$route.path),
            activeLang: this.$api.list.getLangFromPath(this.$route.path),
        };
    }
    // Other component options
};

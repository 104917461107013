import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=2384e7b8"
import script from "./Modal.vue?vue&type=script&lang=js"
export * from "./Modal.vue?vue&type=script&lang=js"
import style0 from "./Modal.vue?vue&type=style&index=0&id=2384e7b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/srv/app/components/Button.vue').default,Markdown: require('/srv/app/components/Markdown.vue').default,Button: require('/srv/app/components/Button.vue').default})


import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

export default {
  props: {
    data: Array,
    isLinked: Boolean,
    Link: String,
    withImage: Boolean,
    iconPosition: String,
    Carousel: Boolean,
  },
  created() {
    this.Carousel &&
      setTimeout(() => {
        this.initCarousel();
      }, 500);
  },
  methods: {
    initCarousel() {
      const swiper = new Swiper(".bordered-box-container", {
        spaceBetween: 30,
        allowTouchMove: false,
        grabCursor: false,
        paginationClickable: false,
        centeredSlides: false,
        slidesPerColumnFill: "row",
        touchMoveStopPropagation: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            slidesPerColumn: 1,
            paginationClickable: true,
            allowTouchMove: true,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            slidesPerColumn: 1,
            paginationClickable: true,
            allowTouchMove: true,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            slidesPerColumn: 3,
            paginationClickable: true,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },
  },
};

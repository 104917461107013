
import mixins from "~/mixins/mixins.js";

export default {
  name: "index",
  mixins: [mixins],
  data() {
    return {
      pageData: {},
      metaData: {},
      modalDisplay: false,
    };
  },
  async fetch() {
    const { data } = await this.$axios.get(
      `/home?_locale=${
        this.activeLang && this.activeLang.length > 0 ? this.activeLang : "tr"
      }`
    );
    this.pageData = data;
    this.metaData = data.SEO;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 100) this.modalDisplay = true;
    },
  },
};

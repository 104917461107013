/**
 * Vue.js component options for incorporating mixins related to page head and language handling.
 *
 * @type {Object}
 *
 * @description
 * This Vue.js component imports and incorporates mixins for managing page head properties
 * and language-related functionalities. The 'head' mixin is responsible for dynamically
 * generating HTML head properties, including title, description, and SEO attributes.
 * The 'lang' mixin handles language-related operations, such as setting and retrieving
 * the active language. By combining these mixins, the component gains enhanced control
 * over page metadata and language settings.
 *
 * @example
 * / Using the PageMixins component in a Vue file
 * export default {
 *   mixins: [head, lang],
 *   / Other component options
 * }
 */
import head from '~/mixins/head.js';  // Importing the 'head' mixin
import lang from '~/mixins/lang.js';  // Importing the 'lang' mixin

export default {
    /**
     * Mixins for incorporating page head and language handling functionalities.
     *
     * @type {Array}
     */
    mixins: [head, lang],
    // Other component options
};

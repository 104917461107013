
export default {
  props: ["items", "showPlus"],
  data() {
    return {
      isFired: false,
    };
  },
  mounted() {
    this.isInViewPort();
  },
  beforeMount() {
    window.addEventListener("scroll", this.isInViewPort);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.isInViewPort);
  },
  methods: {
    isInViewPort() {
      let element = document.querySelector(".count");
      var bounding = element.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.countMe();
      } else {
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    countMe() {
      if (!this.isFired) {
        const counters = document.querySelectorAll(".count");
        const countersStr = document.querySelectorAll(".str");
        counters.forEach((counter) => {
          counter.innerText = "0";

          // const target = +counter.getAttribute('data-count');
          const target = counter.getAttribute("data-count").match(/\d+/)[0];

          const interval = target / 100;

          const updateCounter = () => {
            const addValue = Number(counter.innerText.replace(".", ""));
            const value = addValue;
            if (value < target) {
              const newValue = Math.ceil(value + interval).toString();
              const textValue = this.numberWithCommas(newValue);
              counter.innerText = textValue;

              setTimeout(() => {
                updateCounter();
              }, 20);
            }
          };

          updateCounter();
        });

        this.isFired = true;
      }
    },
  },
};

/**
 * Vue.js component options for managing page metadata and SEO attributes.
 *
 * @type {Object}
 *
 * @description
 * This Vue.js component provides dynamic page metadata and SEO attributes.
 * It generates HTML head properties such as title, description, keywords, and
 * Open Graph (OG) and Twitter card metadata based on the provided 'metaData' object.
 * If 'metaData' is not available, default values for title and language are provided.
 * The component also sets additional metadata, including the current URL and share image.
 *
 * @example
 * / Using the Metadata component in a Nuxt.js page
 * export default {
 *   mixins: [dateMixin],  // Assuming you have a dateMixin for date formatting
 *   data() {
 *     return {
 *       metaData: {
 *         title: 'Page Title',
 *         description: 'Page Description',
 *         keywords: 'keyword1, keyword2, keyword3',
 *         / ...other metadata properties
 *       },
 *       activeLang: 'en',  // Assuming you have an 'activeLang' data property
 *     };
 *   },
 *   / Other component options
 * }
 */
export default {
  /**
   * Function to generate HTML head properties dynamically.
   *
   * @returns {Object} - An object containing HTML head properties.
   */
  head() {
    if (this.metaData)
      return {
        title: this.metaData.title,
        htmlAttrs: {
          lang: !!this.activeLang ? this.activeLang : 'tr',
        },
        meta: [
          {
            hid: `description`,
            name: "description",
            content: this.metaData.description,
          },
          {
            hid: `keywords`,
            name: "keywords",
            keywords: this.metaData.keywords,
          },
          { property: "og:image", content: this.metaData.shareImage },
          { property: "og:title", content: this.metaData.title },
          { property: "og:description", content: this.metaData.description },
          { property: "og:url", content: this.metaData.url },
          { name: "twitter:title", content: this.metaData.title },
          { name: "twitter:description", content: this.metaData.description },
          { name: "twitter:url", content: this.metaData.url },
          { name: "twitter:image", content: this.metaData.shareImage },
          { property: "og:image:width", content: "540" },
          { property: "og:image:height", content: "282" },
        ],
      };
    else {
      return {
        title: 'MikroGrup',
        htmlAttrs: {
          lang: !!this.activeLang ? this.activeLang : 'tr',
        },
      };
    }
  },

  /**
   * Lifecycle hook called after the component has been mounted.
   */
  async mounted() {
    this.setMetaData();
  },

  /**
   * Method to set additional metadata properties such as URL and share image.
   */
  methods: {
    /**
     * Set additional metadata properties like URL and share image.
     */
    setMetaData() {
      if (this.metaData) {
        this.metaData["url"] = window.location.href;
        this.metaData["shareImage"] = `${this.metaData ? (this.metaData.shareImage ? (this.metaData.url ? `${this.metaData.url}${this.metaData.shareImage}` : '') : '') : ''}`;
      }
    }
  }
};

import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c32baa8c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/srv/app/components/Banner.vue').default,Modal: require('/srv/app/components/Modal.vue').default,ColumnBox: require('/srv/app/components/ColumnBox.vue').default,OvalGradient: require('/srv/app/components/OvalGradient.vue').default,RoundBorderedBoxCompanies: require('/srv/app/components/RoundBorderedBoxCompanies.vue').default,Button: require('/srv/app/components/Button.vue').default,RoundedBox: require('/srv/app/components/RoundedBox.vue').default})
